.section-savings {
  .savings-information {
    background-color: white;
    padding: 32px 57px;
    height: 100%;
    @media (max-width: 767px) {
      padding: 30px;
    }
    .text-container {
      margin-bottom: 50px;
      .sub-text-container {
        margin-top: 20px;
        ul {
          // padding-left: 10px;
          li {
            font-size: 16px;
            line-height: 2;
            color: #4f4f4f;
            a {
              font-size: 15px;
              color: black;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .paragraph {
      max-width: 823px;
    }
    .paragraph-small {
      max-width: 691px;
    }
    .header-box {
      margin-bottom: 10px;
    }
  }
  .related-products {
    background-color: white;
    padding: 25px 65px 37px 25px;
    // max-width: 271px;
    @media (max-width: 767px) {
      margin-top: 25px;
    }
    .semi-bold-text {
      margin-bottom: 35px;
    }
    li {
      padding: 17px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      a {
        font-size: 16px;
        color: $text-color;
        &:hover {
          color: darken($color: $text-color, $amount: 40);
          font-weight: 500;
        }
      }
    }
    li + li {
      border-top: 1px solid $border-color;
    }
  }
}
