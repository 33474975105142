.card-big {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.card-small {
  @media (max-width: 767px) {
    margin-top: 20px;
  }
}
.card-misc {
  padding: 40px 30px 40px 45px;
  background-color: $white;
  height: 100%;
}
.card-misc-header {
  margin-bottom: 35px;
}
.card-misc-paragraph {
  margin-bottom: 45px;
  p {
    font-size: 18px;
    color: $text-color;
  }
}
.card-misc-list {
  list-style: disc;
  color: $text-light-color;
  padding-left: 30px;
  margin-bottom: 70px;
  li {
    &::marker {
      font-size: 18px;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    a {
      color: #444444;
      text-decoration: none;
      font-size: 16px;
      &:hover{
        color: red;
      }
    }
  }
}
.misc-link {
  list-style: none;
  a {
    font-size: 18px;
    display: flex;
    gap: 5px;
    align-items: center;
    &:hover,
    &:focus,
    &:active {
      // color: darken($color: $primary, $amount: 10);
      color: red;
      text-decoration: none;
          font-weight: 500;
    }
  }
}
.card-small-list {
  padding-left: 25px;
  margin-bottom: 70px;
  a {
    color: $text-color;
    text-decoration: none;
    font-size: 16px;
    // transition: all 0.3s ease-in-out;
    &:hover {
      // color: darken($color: $text-color, $amount: 40);
      font-weight: 500;
      color: red;
    }
  }
  li {
    &:not(:last-child) {
      margin-bottom: 12px;
      padding-bottom: 10px;
      border-bottom: solid 1px $gray-shade-1;
    }
  }
}
