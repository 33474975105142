.section-sub-header {
  margin-bottom: 0;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 28px 52px 22px 55px;
    @media (max-width: 640px) {
      flex-direction: column;
      gap: 10px;
    }
    .location-dropdown {
      @media (max-width: 640px) {
        order: 2;
      }
    }
  }
  .semi-bold-text {
    color: #333;
    margin-bottom: 8px;
  }
  .small-light-text {
    text-align: right;
    @media (max-width: 640px) {
      text-align: center;
    }
  }
  select {
    color: $list-color;
    border-radius: 5px;
    background: transparent;
    padding: 5px 50px 5px 14px;
    &:focus-visible {
      outline: none;
    }
    // &::after {
    //   content: "";
    //   display: block;
    //   width: 15px;
    //   height: 15px;
    //   background-image: url("../../images/drop-arrow.png");
    // }
  }
  option {
    padding: 10px;
  }
  .button-container {
    display: flex;
    .btn-tertiary {
      svg {
        margin-right: 10px;
      }
      &:first-child {
        border-top-left-radius: 45px;
        border-bottom-left-radius: 45px;
      }
      &:last-child {
        border-top-right-radius: 45px;
        border-bottom-right-radius: 45px;
      }
    }
    .btn-secondary {
      border: none;
      border-radius: 0;
      background-color: #f2f2f2;
      color: $secondary;
      font-size: 16px;
      font-weight: 400;
    }
    .btn-secondary.active-view {
      background-color: $secondary;
      color: white;
    }
  }
}
