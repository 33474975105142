table {
  width: 100%;
  overflow: auto;
  thead {
    background-color: $secondary;
    color: white;
  }
  th {
    padding: 16px 37px;
    font-weight: 600;
    font-size: 18px;
  }
  td {
    padding: 16px 37px;
    border: 1px solid #e7edff;
    font-size: 16px;
    line-height: 1.5;
    color: #828282;
  }
}

.section-table {
  h1 {
    color: $secondary;
  }
  .tables {
    background: white;
    padding: 10px 68px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      padding: 10px 40px;
    }
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
    .header {
      background-color: $secondary;
      align-self: self;
      padding: 16px 37px;
      @media (max-width: 767px) {
        padding: 16px 10px;
      }
      p {
        color: white;
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
    .header + .header {
      border-left: 1px solid $light-purple;
    }
    .content {
      align-self: center;
      padding: 16px 37px;
      border-bottom: 1px solid $light-purple;
      border-left: 1px solid $light-purple;
      @media (max-width: 767px) {
        padding: 16px 10px;
        align-self: flex-end;
      }
      p {
        color: $list-color;
      }
    }
    .content + .content {
      border-right: 1px solid $light-purple;
    }
    // .misc-link {
    //   a {
    //     font-size: 14px;
    //   }
    // }
  }
  .two-column {
    .header {
      flex-basis: 50%;
    }
    .content {
      flex-basis: 50%;
    }
  }
  .three-column {
    .header {
      flex-basis: 33.33%;
    }
    .content {
      flex-basis: 33.33%;
    }
  }
  .four-column {
    .header {
      flex-basis: 25%;
    }
    .content {
      flex-basis: 25%;
    }
  }
  .five-column {
    .header {
      flex-basis: 20%;
    }
    .content {
      flex-basis: 20%;
    }
  }
  .third-variant {
    .header {
      &:nth-child(odd) {
        flex-basis: 80%;
        @media (max-width: 1095px) {
          flex-basis: 70%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
      &:nth-child(even) {
        flex-basis: 20%;
        @media (max-width: 1095px) {
          flex-basis: 30%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
    }
    .content {
      &:nth-child(odd) {
        flex-basis: 80%;
        @media (max-width: 1095px) {
          flex-basis: 70%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
      &:nth-child(even) {
        flex-basis: 20%;
        @media (max-width: 1095px) {
          flex-basis: 30%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
      .misc-link {
        a {
          svg {
            @media (max-width: 350px) {
              display: none;
            }
          }
        }
      }
    }
  }
  .fourth-variant {
    .header {
      &:nth-child(even) {
        flex-basis: 80%;
        @media (max-width: 1095px) {
          flex-basis: 70%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
      &:nth-child(odd) {
        flex-basis: 20%;
        @media (max-width: 1095px) {
          flex-basis: 30%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
    }
    .content {
      &:nth-child(even) {
        flex-basis: 80%;
        @media (max-width: 1095px) {
          flex-basis: 70%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
      &:nth-child(odd) {
        flex-basis: 20%;
        @media (max-width: 1095px) {
          flex-basis: 30%;
        }
        @media (max-width: 512px) {
          flex-basis: 50%;
        }
      }
      .misc-link {
        a {
          svg {
            @media (max-width: 350px) {
              display: none;
            }
          }
        }
      }
    }
  }
}
