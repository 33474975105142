.section-banner {
  margin: 0;
  position: relative;
  max-height: 175px;
  .header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      color: white;
    }
  }
  .image-container {
    max-height: 175px;
    overflow: hidden;
    img {
      @media (max-width: 767px) {
        height: 40vh;
        object-fit: cover;
        object-position: center;
      }
    }
  }

}
.section-secondary-banner {
  margin-bottom: 45px;
  margin-top: 35px;
}
