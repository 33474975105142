.overview {
  .wrapper {
    background-color: white;
    padding: 0 35px;
  }
  .heading {
    display: flex;
    align-items: center;
    gap: 55px;
    padding: 56px 25px 0px;
    .small-light-text {
      max-width: 952px;
      text-align: justify;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  .image-container {
    width: 500px;
    margin: auto;
    padding-bottom: 35px;
        padding-top: 35px;
  }
  .inner-wrapper {
    padding: 68px 47px;
    background-color: $body-bg-color;
    @media (max-width: 500px) {
      padding: 50px 10px;
    }
    .message-box {
      display: flex;
      height: 152px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      &:first-child {
        margin-bottom: 24px;
        @media (max-width: 500px) {
          margin-bottom: 60px;
        }
      }
      .text-container{
        p{
          text-align: left;
          padding: initial;

        }
      }
      p {
        &:last-child {
          padding: 25px;
          @media (max-width: 500px) {
            padding-left: 10px !important;
          }
        }
      }
      .title {
        background-color: $secondary;
        color: white;
        width: 252px;
        flex-shrink: 0;
        @media (max-width: 767px) {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
  }

}
