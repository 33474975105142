footer {
  padding: 85px 75px 20px;
  background-color: $secondary;
  @media (max-width: 767px) {
    padding: 50px 20px;
  }
  a {
    font-size: 15px;
    color: $white;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:focus,
    &:active {
      color: $primary;
      // font-weight: 500;
      text-decoration: none;
    }
  }
  .footer-logo-container {
    height: 100%;
    .address-contact {
      margin-top: 10px;
      padding-left: 8px;
      p {
        color: white;
        font-size: 13px;
        margin-bottom: 3px;
      }
    }
  }

  .footer-row {
    padding-bottom: 35px;
    border-bottom: solid 1px $white;
    .row {
      row-gap: 25px;
      .col-6 {
        @media (max-width: 400px) {
          width: 100%;
        }
      }
    }
  }
  .footer-list {
    li {
      margin-bottom: 15px;
    }
  }
  .footer-content {
    padding-top: 35px;
    p {
      font-size: 13px;
      color: $white;
    }
    .row {
      row-gap: 25px;
      align-items: flex-end;
    }
    .head {
      margin-bottom: 10px;
      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
    .head-long {
      width: 285px;
      @media (max-width: 767px) {
        width: initial;
      }
    }
    .image-container {
      display: flex;
      column-gap: 10px;
      .image {
        width: 116px;
      }
    }
    .icon-container {
      display: flex;
      gap: 18px;
      margin-top: 18px;
      .icon {
        cursor: pointer;
      }
      svg {
        path {
          fill: white;
        }
      }
    }
  }
}
.footer-banner {
  background-color: $white;
  color: $text-color;
  padding: 14px 0;
  display: flex;
  justify-content: center;
}
